<template>
    <div style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable clearable>
                                <el-option
                                    v-for="item of storeDepts"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="营业执照">
                            <el-input placeholder="营业执照名称" v-model="form.deptLicenseName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="状态">
                            <el-select placeholder="状态" v-model="form.closeDeptProcess" clearable>
                                <el-option label="待退费" value="CLOSED" />
                                <el-option label="已退费" value="CLEANUP_COMPLETED" />
                                <el-option label="退店中" value="CLOSING" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.system.closeDeptBiz.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.system.closeDeptBiz.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
                v-loading="tableLoading"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="编码" width="200" prop="code" v-if="showColumn('code')" />
                <el-table-column label="机构名称" width="200" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column
                    label="营业执照名称"
                    width="200"
                    prop="deptLicenseName"
                    v-if="showColumn('deptLicenseName')"
                />
                <el-table-column label="申请人" width="100" prop="applyStaffName" v-if="showColumn('applyStaffName')" />
                <el-table-column label="申请退店时间" width="150" prop="applyTime" v-if="showColumn('applyTime')" />
                <el-table-column label="状态" width="100" prop="closeProcess" v-if="showColumn('closeProcess')">
                    <template slot-scope="scope">
                        <span>{{ scope.row.closeProcess | closeDeptProcessDesc }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="退费操作人"
                    width="100"
                    prop="cleanupOperatorName"
                    v-if="showColumn('cleanupOperatorName')"
                />
                <el-table-column
                    label="停用日期"
                    width="150"
                    prop="agreementWrittenCloseTime"
                    v-if="showColumn('agreementWrittenCloseTime')"
                />
                <el-table-column
                    label="操作"
                    min-width="200"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="openHandleCleanupClosedDeptRenewDialog(scope.row)"
                            v-if="hasPrivilege('menu.system.closeDeptBiz.open')"
                            :disabled="scope.row.disableCleanUpRenewBtn"
                            >退费
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="openHandelCloseDeptDialog(scope.row)"
                            v-if="hasPrivilege('menu.system.closeDeptBiz.open')"
                            >查看退店
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
        <HandleCleanupClosedDeptRenewDialog ref="handleCleanupClosedDeptRenewDialog" @afterSuccess="handleQuery" />
        <HandleCloseDeptDialog ref="handleCloseDeptDialog" @afterSuccess="handleQuery" />
    </div>
</template>

<script>
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import Util from 'js/Util';
import EfPagination from 'components/EfPagination';
import UrlUtils from 'js/UrlUtils';
import HandleCleanupClosedDeptRenewDialog from 'views/menu/system/dept/HandleCleanupClosedDeptRenewDialog.vue';
import HandleCloseDeptDialog from 'views/menu/system/dept/HandleCloseDeptDialog.vue';

export default {
    name: 'CloseDeptBizPage',
    components: { HandleCloseDeptDialog, HandleCleanupClosedDeptRenewDialog, CheckTableShowColumnDialog, EfPagination },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            tableLoading: false,
            form: {
                deptCode: '',
                deptLicenseName: '',
                closeDeptProcess: null,
                page: 1,
                limit: Util.Limit,
            },
            tableData: [],
            total: 0,
            storeDepts: [],
        };
    },
    mounted() {
        this.$efApi.deptApi.companyAllStores(false).then((rst) => {
            this.storeDepts = rst;
        });
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleQuery() {
            this.form.page = 1;
            this.queryList();
        },
        async queryList() {
            this.tableLoading = true;
            try {
                const rst = (await this.$http.get(`/system/closeDeptBiz/queryPage`, { params: this.form }))?.data;
                this.tableData = rst.data.map((e) => {
                    return {
                        ...e,
                        //根据状态设置操作按钮禁用或启用
                        disableCleanUpRenewBtn: e.closeProcess !== 'CLOSED',
                    };
                });
                this.total = rst.count;
            } finally {
                this.tableLoading = false;
            }
        },
        handleExport() {
            UrlUtils.Export(this, '退店记录', '/system/closeDeptBiz/exportPage', this.form, {});
        },
        openHandelCloseDeptDialog(row) {
            this.$refs.handleCloseDeptDialog.show(row.deptCode, true);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        openHandleCleanupClosedDeptRenewDialog(row) {
            this.$refs.handleCleanupClosedDeptRenewDialog.show(row.deptCode);
        },
    },
};
</script>
