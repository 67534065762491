<template>
    <div>
        <el-dialog
            title="退费"
            :visible.sync="dialogVisible"
            width="40%"
            top="60px"
            :destroyOnClose="true"
            @close="close"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-form
                    :model="form"
                    @submit.native.prevent
                    ref="form"
                    label-width="200px"
                    size="small"
                    :rules="rules"
                >
                    <el-row>
                        <el-col>
                            <el-form-item label="机构名称：">{{ closeDeptBizDto.deptName }}</el-form-item>
                            <el-form-item label="营业执照名称：">{{ closeDeptBizDto.deptLicenseName }}</el-form-item>
                            <el-form-item label="申请人：">{{ closeDeptBizDto.applyStaffName }}</el-form-item>
                            <el-form-item label="申请退店时间：">{{ closeDeptBizDto.applyTime }}</el-form-item>
                            <el-form-item label="当前到期时间：">{{ closeDeptBizDto.effectiveTime }}</el-form-item>
                            <el-form-item label="停用日期：" prop="agreementWrittenCloseTime">
                                <el-date-picker
                                    placeholder="选择日期"
                                    v-model="form.agreementWrittenCloseTime"
                                    value-format="yyyy-MM-dd"
                                    :clearable="true"
                                    :editable="true"
                                    @change="changeAgreementWrittenCloseTime"
                                />
                            </el-form-item>
                            <el-form-item label="剩余时长：">{{ form.remainNeedCleanupDuration }}</el-form-item>
                            <el-form-item label="退费类型：" prop="cleanupType">
                                <el-radio-group
                                    v-model="form.cleanupType"
                                    @change="changeCleanupType"
                                    :disabled="disableChangeCleanupTypeFlag"
                                >
                                    <el-radio label="REFUND_MONEY">退费</el-radio>
                                    <el-radio label="MIGRATE_REMAIN_VALID_PERIOD">转赠</el-radio>
                                    <el-radio label="NONE">不退费</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <template v-if="form.cleanupType === 'REFUND_MONEY'">
                                <el-form-item label="退费金额：" prop="refundAmount">
                                    <el-input-number v-model.number="form.refundAmount" :min="0.01" :precision="2" />
                                </el-form-item>
                                <el-form-item label="退费日期：" prop="refundDate">
                                    <el-date-picker
                                        placeholder="选择日期"
                                        v-model="form.refundDate"
                                        value-format="yyyy-MM-dd"
                                        :clearable="true"
                                        :editable="true"
                                    />
                                </el-form-item>
                            </template>
                            <template v-else-if="form.cleanupType === 'MIGRATE_REMAIN_VALID_PERIOD'">
                                <el-form-item label="转赠机构：" prop="migrateTargetDeptCode">
                                    <el-select v-model="form.migrateTargetDeptCode">
                                        <el-option label="请选择" value="" />
                                        <el-option
                                            v-for="item in storeDepts"
                                            :label="item.name"
                                            :value="item.code"
                                            :key="item.code"
                                        />
                                    </el-select>
                                </el-form-item>
                            </template>
                            <template v-if="form.cleanupType === 'NONE'">
                                <el-form-item label="不退费原因：" prop="noneProcessReason">
                                    <el-input
                                        v-model.trim="form.noneProcessReason"
                                        type="textarea"
                                        :show-word-limit="true"
                                        :maxlength="200"
                                        :minlength="1"
                                        :disabled="disableChangeCleanupTypeFlag"
                                    />
                                </el-form-item>
                            </template>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
            <div slot="footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="handleCleanupRenew">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { DeptTypeEnum } from 'js/DeptCommon';
import { Message } from 'element-ui';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';

export default {
    name: 'HandleCleanupClosedDeptRenewDialog',
    data() {
        return {
            dialogVisible: false,
            storeDepts: [],

            closeDeptBizDto: {
                deptCode: '',
                deptName: '',
                deptLicenseName: '',
                applyStaffCode: '',
                applyStaffName: '',
                applyTime: null,
                effectiveTime: null,
                closeProcess: null,
                agreementWrittenCloseTime: null,
                remainNeedCleanupDays: null,
                cleanupType: null,
                refundAmount: null,
                refundDate: null,
                migrateTargetDeptCode: null,
                noneProcessReason: null,
            },
            form: {
                cleanupRenewDeptCode: null,
                agreementWrittenCloseTime: null,
                remainNeedCleanupDays: null,
                remainNeedCleanupDuration: null,
                cleanupType: null,
                refundAmount: undefined,
                refundDate: null,
                migrateTargetDeptCode: null,
                noneProcessReason: null,
            },
            disableChangeCleanupTypeFlag: false,
            rules: {
                agreementWrittenCloseTime: { required: true, message: '请填写停用日期', trigger: ['blur'] },
                cleanupType: { required: true, message: '请选择退费类型', trigger: ['blur'] },
                refundAmount: { required: true, message: '请输入退费金额', trigger: ['blur'] },
                refundDate: { required: true, message: '请选择退费日期', trigger: ['blur'] },
                migrateTargetDeptCode: { required: true, message: '请选择转赠机构', trigger: ['blur'] },
                noneProcessReason: { required: true, message: '请填写不退费原因', trigger: ['blur'] },
            },
        };
    },
    watch: {
        'form.refundAmount': {
            handler(n, o) {
                if (n === null) {
                    this.form.refundAmount = undefined;
                }
            },
            immediate: true,
        },
    },
    methods: {
        async show(deptCode) {
            if (!deptCode) {
                this.$message.error('未选择机构');
                return false;
            }
            this.storeDepts = await this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE);
            //排除掉退店机构
            this.storeDepts = this.storeDepts.filter((e) => e.code !== deptCode);

            //查询申请退店业务数据
            await this.queryDeptCloseBiz(deptCode);
            //延迟显示弹窗
            this.dialogVisible = true;
        },
        async queryDeptCloseBiz(deptCode) {
            //查询申请退店业务数据
            this.closeDeptBizDto = (await this.$http.get(`/system/closeDeptBiz/query/${deptCode}`))?.data?.data;
            this.form.cleanupRenewDeptCode = this.closeDeptBizDto.deptCode;
            this.form.agreementWrittenCloseTime = this.closeDeptBizDto.agreementWrittenCloseTime;
            this.form.remainNeedCleanupDays = this.closeDeptBizDto.remainNeedCleanupDays;
            this.form.remainNeedCleanupDuration = this.closeDeptBizDto.remainNeedCleanupDuration;
            this.form.cleanupType = this.closeDeptBizDto.cleanupType;
            this.form.refundAmount = this.closeDeptBizDto.refundAmount;
            this.form.refundDate = this.closeDeptBizDto.refundDate;
            this.form.migrateTargetDeptCode = this.closeDeptBizDto.migrateTargetDeptCode;
            this.form.noneProcessReason = this.closeDeptBizDto.noneProcessReason;
        },
        handleCleanupRenew() {
            this.$refs.form.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查');
                    return false;
                }
                await this.$http.post('/system/closeDept/cleanupRenew/execute', this.form).then((rst) => {
                    if (rst.data.status === 200) {
                        Message.success({ message: '操作成功' });
                        this.close();
                        this.$emit('afterSuccess');
                    } else {
                        Message.error({ message: '操作失败：' + rst.data.message });
                    }
                });
            });
        },
        close() {
            this.dialogVisible = false;
            Object.assign(this.$data, this.$options.data());
        },
        async changeAgreementWrittenCloseTime() {
            if (this.form.remainNeedCleanupDays <= 0) {
                this.form.cleanupType = null;
                this.form.noneProcessReason = null;
                this.disableChangeCleanupTypeFlag = false;
                this.changeCleanupType();
            }
            if (!this.closeDeptBizDto.effectiveTime) {
                Message.error({ message: '机构到期时间未设置，无法继续操作' });
            }
            if (this.form.agreementWrittenCloseTime) {
                dayjs.extend(durationPlugin);
                const startDate = dayjs(this.form.agreementWrittenCloseTime, 'YYYY-MM-DD');
                const endDate = dayjs(this.closeDeptBizDto.effectiveTime, 'YYYY-MM-DD');
                const duration = dayjs.duration(endDate.diff(startDate));
                this.form.remainNeedCleanupDays = duration.asDays();
                this.form.remainNeedCleanupDuration = (
                    await this.$http.get(`/datePeriod/monthDaysBetweenLocalDate`, {
                        params: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') },
                    })
                )?.data?.data;
                if (this.form.remainNeedCleanupDays <= 0) {
                    this.form.cleanupType = 'NONE';
                    this.form.noneProcessReason = '系统到期';
                    this.disableChangeCleanupTypeFlag = true;
                    this.changeCleanupType();
                }
            } else {
                this.form.remainNeedCleanupDays = null;
                this.form.remainNeedCleanupDuration = null;
            }
        },
        changeCleanupType() {
            const cleanupType = this.form.cleanupType;
            if (cleanupType !== 'REFUND_MONEY') {
                this.form.refundAmount = null;
                this.form.refundDate = null;
            }
            if (cleanupType !== 'MIGRATE_REMAIN_VALID_PERIOD') {
                this.form.migrateTargetDeptCode = null;
            }
            if (cleanupType !== 'NONE') {
                this.form.noneProcessReason = null;
            }
        },
    },
};
</script>
